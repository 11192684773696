/**
 * Easy selector helper function
 */
const select = (el, all = false) => {
  el = el.trim()
  if (all) {
    return [...document.querySelectorAll(el)]
  } else {
    return document.querySelector(el)
  }
}
/**
 * Easy event listener function
 */
const on = (type, el, listener, all = false) => {
  let selectEl = select(el, all)
  if (selectEl) {
    if (all) {
      selectEl.forEach(e => e.addEventListener(type, listener))
    } else {
      selectEl.addEventListener(type, listener)
    }
  }
}
/**
 * Easy on scroll event listener 
 */
const onscroll = (el, listener) => {
  el.addEventListener('scroll', listener)
}
/**
 * Scrolls to an element with header offset
 */
  const scrollto = (el) => {
  let elementPos = select(el).offsetTop
  window.scrollTo({
    top: elementPos,
    behavior: 'smooth'
  })
}

// 참고 : https://stackoverflow.com/questions/54501241/rails-5-6-how-to-include-js-functions-with-webpacker/56549843
document.addEventListener("turbolinks:load", () => {
  /**
   * Navbar links active state on scroll
   */
  let navbarlinks = select('#navbar .scrollto', true)
  const navbarlinksActive = () => {
    let position = window.scrollY + 200
    navbarlinks.forEach(navbarlink => {
      if (!navbarlink.hash) return
      let section = select(navbarlink.hash)
      if (!section) return
      if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
        navbarlink.classList.add('active')
      } else {
        navbarlink.classList.remove('active')
      }
    })
  }
  window.addEventListener('load', navbarlinksActive)
  onscroll(document, navbarlinksActive)

  /**
   * Back to top button
   */
  let backtotop = select('.back-to-top')
  if (backtotop) {
    onscroll(document, () => {
      if (window.scrollY > 100) {
        backtotop.classList.add('active')
      } else {
        backtotop.classList.remove('active')
      }
    })
  }

  /**
   * Mobile nav toggle
   */
  on('click', '.mobile-nav-toggle', function(e) {
    select('body').classList.toggle('mobile-nav-active')
    this.classList.toggle('bi-list')
    this.classList.toggle('bi-x')
  })

  /**
   * Scroll with ofset on links with a class name .scrollto
   */
  on('click', '.scrollto', function(e) {
    if (select(this.hash)) {
      e.preventDefault()

      let body = select('body')
      if (body.classList.contains('mobile-nav-active')) {
        body.classList.remove('mobile-nav-active')
        let navbarToggle = select('.mobile-nav-toggle')
        navbarToggle.classList.toggle('bi-list')
        navbarToggle.classList.toggle('bi-x')
      }
      scrollto(this.hash)
    }
  }, true)

  /**
   * Scroll with ofset on page load with hash links in the url
   */
   window.addEventListener('load', () => {
    if (window.location.hash) {
      if (select(window.location.hash)) {
        scrollto(window.location.hash)
      }
    }
  });

  /**
   * Preloader
   */
  let preloader = select('#preloader');
  if (preloader) {
    window.addEventListener('load', () => {
      preloader.remove()
    });
  }

});


var delay = 1000;
var timer = null;
var window_width = window.screen.width;
//Javascript
window.addEventListener('resize', function(){
  var current_window_width = window.screen.width;
  if (window_width < 576) {
    if (current_window_width >= 576) {
      clearTimeout(timer);
      timer = setTimeout(function(){
        location.reload();
      }, delay);
      window_width = window.screen.width
    }
  } else if (window_width < 768) {
    if (current_window_width >= 768 || current_window_width < 576) {
      clearTimeout(timer);
      timer = setTimeout(function(){
        location.reload();
      }, delay);
      window_width = window.screen.width
    }
  } else if (window_width < 992) {
    if (current_window_width >= 992 || current_window_width < 768) {
      clearTimeout(timer);
      timer = setTimeout(function(){
        location.reload();
      }, delay);
      window_width = window.screen.width
    }
  } else if (window_width < 1200) {
    if (current_window_width >= 1200 || current_window_width < 992) {
      clearTimeout(timer);
      timer = setTimeout(function(){
        location.reload();
      }, delay);
      window_width = window.screen.width
    }
  } else if (window_width < 1400) {
    if (current_window_width < 1200) {
      clearTimeout(timer);
      timer = setTimeout(function(){
        location.reload();
      }, delay);
      window_width = window.screen.width
    }
  }

  
	
});

// 현재 넓이에서 포인트 달라질때만