// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require.context('../images', true)
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
// jqeury
import "jquery"
// Bootstrap
import '../js/bootstrap_js_files.js'  
require('bootstrap-icons/font/bootstrap-icons.css');
// Boxicons
require('bootstrap-icons/font/bootstrap-icons.css');
import 'boxicons/css/boxicons.min.css';
// AOS
import AOS from 'aos';
import 'aos/dist/aos.css';
document.addEventListener('DOMContentLoaded', function() {
  AOS.init({
    startEvent: 'turbolinks:load', // if you are using turbolinks
    duration: 1000,
    easing: "ease-in-out",
    once: false,
    mirror: true,
    disable: 'mobile'
  });
});
// skrollr
import Skrollr from 'skrollr';
// var _skrollr = Skrollr.get(); 
var windowWidth = window.screen.width;
document.addEventListener('DOMContentLoaded', function() {
  // console.log(windowWidth)
  if ( windowWidth > 576) {
    Skrollr.init({ 
      forceHeight: false,
      scale: 1
    })
  }
})
// custom
require('../js/main');

Rails.start()
Turbolinks.start()
ActiveStorage.start()
